import React from "react";

import { BackTop } from 'antd';
import { FacebookFilled, InstagramFilled, UpOutlined } from '@ant-design/icons';

import logo from "../../assets/images/logo.png";

function AppFooter() {
    const year = new Date().getFullYear();

    return (
        <div className="container-fluid mobileHidden">
            <div className="footer">
                <div className="logo">
                    <a title="Le Xylophile" href="https://www.lexylophile.be">
                       <img className="svg_logo" height={80} src={logo} alt="Le Xylophile"/>
                    </a>
                </div>
                <ul className="socials">
                </ul>
                <div className="copyright">Auto-hébergé via <a href="https://www.raspberrypi.com/" title="Raspberry Pi" target="_blank" rel="noreferrer">Raspberry Pi</a></div>
                <div className="copyright">Copyright {year} &copy; <a title="Le Xylophile" href="https://www.lexylophile.be">Le Xylophile</a></div>
                <div className="copyright">Logo fabriqué grâce à <a href="https://www.flaticon.com/free-icons/wood" title="Freepik" target="_blank" rel="noreferrer">Freepik</a></div>
                <BackTop>
                    <div className="goTop"><UpOutlined /></div>
                </BackTop>
            </div>
        </div>
    );
}

export default AppFooter;