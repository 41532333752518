import React from "react";
import { Anchor, Button, Carousel } from 'antd';
import { UserOutlined, ToolOutlined } from '@ant-design/icons';

import home_image from "../../assets/images/IMG_0038-zoom.JPG";
import projects_image from "../../assets/images/IMG_0118-zoom.JPG";
import contact_image from "../../assets/images/IMG_0055-zoom.JPG";

const { Link } = Anchor;

function AppHero() {
    return (
        <div id="home" className="heroBlock mobileHidden">
            <Carousel autoplay draggable autoplaySpeed={10000}>
            {
                <div className="container-fluid" key={0}>
                    <div className="contentWrapper">
                        <div className="content">
                            <h3>Bienvenue !</h3>
                            <p>Avec <i><b>Le Xylophile</b></i>, vous optez pour :</p>
                            <p>Des constructions saines et durables.<br/>
                                Un regard créatif sur votre projet.<br/>
                                Une écoute attentive.<br/>
                                Le goût du travail bien fait.<br/>
                                Et la garantie du respect de l'environnement.
                            </p>
                        </div>
                        <div className="image">
                            <img alt="Le Xylophile" src={home_image} />
                        </div>
                    </div>
                </div>
            }
            {
                <div className="container-fluid" key={1}>
                    <div className="contentWrapper">
                        <div className="content">
                            <h3>Jetez un oeil à mes créations !</h3>
                            <p>De la menuiserie à la charpente, je suis passionné par le travail du bois et chaque projet est une nouvelle opportunité de mettre mes compétences et ma créativité au défi.</p>
                            <div className="btnHolder">
                                <Anchor targetOffset={500}>
                                    <Link href="#projects">
                                        <Button href="#projects" title="Projets">
                                            <ToolOutlined />
                                            <p className="link-title">Projets</p>
                                        </Button>
                                    </Link>
                                </Anchor>
                            </div>
                        </div>
                        <div className="image">
                            <img alt="Le Xylophile" src={projects_image} />
                        </div>
                    </div>
                </div>
            }
            {
                <div className="container-fluid" key={2}>
                    <div className="contentWrapper">
                        <div className="content">
                            <h3>Ensemble, donnons vie à vos idées.</h3>
                            <p>Contactez-moi afin de discuter ensemble du projet qui vous tient à coeur. Je vous conseillerai et vous ferai un devis sur mesure ; c'est gratuit et sans engagement.</p>
                            <div className="btnHolder">
                                <Anchor targetOffset={500}>
                                    <Link href="#contact">
                                        <Button href="#contact" title="Contact">
                                            <UserOutlined />
                                            <p className="link-title">Contact</p>
                                        </Button>
                                    </Link>
                                </Anchor>
                            </div>
                        </div>
                        <div className="image">
                            <img alt="Le Xylophile" src={contact_image} />
                        </div>
                    </div>
                </div>
            }
            </Carousel>
        </div>
    );
}

export default AppHero;