import React from "react";

import { Button, Anchor } from 'antd';
import { PhoneOutlined, MailOutlined } from '@ant-design/icons';

function AppContact() {
    return (
        <div id ="contact" className="block contactBlock bgSand">
            <div className="container-fluid mobileHidden">
                <div className="titleHolder">
                    <h2>Contact</h2>
                    <p>Ensemble, donnons vie à vos idées.</p>
                </div>
                <div className="btnHolder">
                  <Anchor>
                    <Button href="&#x74;&#x65;&#x6c;&#x3a;&#x2b;&#x33;&#x32;&#x34;&#x39;&#x35;&#x34;&#x35;&#x31;&#x33;&#x39;&#x30;" title="Appel">
                        <PhoneOutlined />
                        <p className="link-title">Appelez-moi</p>
                    </Button>
                  </Anchor>
                </div>
                <div className="btnHolder">
                  <Anchor>
                    <Button href="&#x6d;&#x61;&#x69;&#x6c;&#x74;&#x6f;&#x3a;&#x63;&#x6f;&#x6e;&#x74;&#x61;&#x63;&#x74;&#x40;&#x6c;&#x65;&#x78;&#x79;&#x6c;&#x6f;&#x70;&#x68;&#x69;&#x6c;&#x65;&#x2e;&#x62;&#x65;" title="E-mail">
                        <MailOutlined />
                        <p className="link-title">Envoyez-moi un e-mail</p>
                    </Button>
                  </Anchor>
                </div>
            </div>
            <div className="mobileVisible">
              <p>Bonjour,</p>
              <p>Afin de contribuer à mon échelle à la diffusion de la nocivité que présentent les smartphones pour la mémoire, la concentration, la qualité des interactions sociales et la planète, j'ai fait le choix conscient de rendre mon site indisponible pour ces appareils.</p>
              <p>Des études démontrent ces phénomènes, des écoles commencent à interdire les smartphones, et le terme d'amnésie numérique commence à être largement répandu. Renseignez-vous.</p>
              <p>Merci de bien vouloir réessayer avec une tablette ou un ordinateur pour voir le contenu de mon site web.</p>
              <p>Steven</p>
            </div>
        </div>
    );
}

export default AppContact;