import React, { useState } from "react";
import { Anchor, Drawer, Button } from "antd";

import logo from "../../assets/images/logo.png";
import logo_text from "../../assets/images/alt_logo_text.png";
import { MenuOutlined, HomeOutlined, UserOutlined, ToolOutlined } from '@ant-design/icons';

const { Link } = Anchor;

function AppHeader() {
    const [visible, setVisible] = useState(false);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    return (
        <div className="container-fluid mobileHidden">
            <div className="header">
                <div className="logo">
                   <a title="Le Xylophile" href="https://www.lexylophile.be">
                       <img className="svg_logo" height={140} src={logo} />
                       <img className="logo_text" height={140} src={logo_text} />
                    </a>
                </div>
                <div className="mobileHidden">
                    <Anchor targetOffset={500}>
                        <Link href="#home">
                            <Button href="#home" title="Accueil">
                                <HomeOutlined />
                                <p className="link-title">Accueil</p>
                            </Button>
                        </Link>
                        <Link href="#projects">
                            <Button href="#projects" title="Projets">
                                <ToolOutlined />
                                <p className="link-title">Projets</p>
                            </Button>
                        </Link>
                        <Link href="#contact">
                            <Button href="#contact" title="Contact">
                                <UserOutlined />
                                <p className="link-title">Contact</p>
                            </Button>
                        </Link>
                    </Anchor>
                </div>
                <div className="mobileVisible">
                    <Button type="primary" onClick={showDrawer}>
                        <MenuOutlined />
                    </Button>
                    <Drawer width={125} placement="right" closable={false} onClose={onClose} open={visible}>
                        <Anchor targetOffset={500}>
                            <Link href="#home" title="Accueil" />
                            <Link href="#projects" title="Projets" />
                            <Link href="#contact" title="Contact" />
                        </Anchor>
                    </Drawer>
                </div>
            </div>
        </div>
    );
}

export default AppHeader;