import React from "react";

import { Card, Image, Carousel } from "antd";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import best24000_cover from "../../assets/images/IMG_0062-zoom.JPG";
import best24000_1 from "../../assets/images/BEST24000/IMG_0063.JPG";

import best24001_cover from "../../assets/images/IMG_0118-zoom.JPG";

import best24002_cover from "../../assets/images/IMG_0038-zoom.JPG";
import best24002_1 from "../../assets/images/BEST24002/4ca6b6ba-d4b3-4071-b64e-2e85cd8427d9.jpeg";
import best24002_2 from "../../assets/images/BEST24002/d8b85674-0d4c-4c68-a155-9e29a017e5f1.jpeg";
import best24002_3 from "../../assets/images/BEST24002/IMG_0040.JPG";

import best24004_cover from "../../assets/images/IMG_0117-zoom.JPG";

import best24004_5_cover from "../../assets/images/BEST24004.5/IMG_0131-zoom.JPG";
import best24004_5_1 from "../../assets/images/BEST24004.5/IMG_0130-zoom.JPG";
import best24004_5_2 from "../../assets/images/BEST24004.5/IMG_0129-zoom.JPG";

import best24005_cover from "../../assets/images/IMG_0186-zoom.JPG";

import best24008_cover from "../../assets/images/BEST24008/IMG_0230-zoom.JPG";
import best24008_1 from "../../assets/images/BEST24008/IMG_0231.JPG";
import best24008_2 from "../../assets/images/BEST24008/IMG_0232.JPG";
import best24008_3 from "../../assets/images/BEST24008/IMG_0234.JPG";
import best24008_4 from "../../assets/images/BEST24008/IMG_0236.JPG";

const { Meta } = Card;

function AppProjects() {
    return (
        <div id="projects" className="block featureBlock bgDarkBrown mobileHidden">
            <div className="container-fluid">
                <div className="titleHolderEven">
                    <h2>Projets</h2>
                    <p>Jetez un oeil à mes créations !</p>
                </div>
                <div className="content">
                    
                    <Carousel arrows nextArrow={<RightOutlined />} prevArrow={<LeftOutlined />} dots={false} speed={500} effect={"fade"} adaptiveHeight={true} accessibility={false}>
                    {
                        <div className="container-fluid" key={0}>
                            <div className="contentWrapper">
                            <Card
                                    className="project-card"
                                    cover=
                                    {<Image.PreviewGroup>
                                        <Image src={best24008_cover} />
                                        <Image width={0} src={best24008_1} delay={3000} />
                                        <Image width={0} src={best24008_2} delay={3000} />
                                        <Image width={0} src={best24008_3} delay={3000} />
                                        <Image width={0} src={best24008_4} delay={3000} />
                                    </Image.PreviewGroup>}
                                >
                                    <Meta
                                        title="Terrasse & toilette sèche"
                                        description="Espace extérieur agréable et fonctionnel."
                                    />
                                </Card>
                                <Card
                                    className="project-card"
                                    cover=
                                    {<Image.PreviewGroup>
                                        <Image src={best24005_cover} />
                                    </Image.PreviewGroup>}
                                >
                                    <Meta
                                        title="Appentis"
                                        description="Abri de rangement."
                                    />
                                </Card>
                                <Card
                                    className="project-card"
                                    cover=
                                    {<Image.PreviewGroup>
                                        <Image src={best24004_5_cover} />
                                        <Image width={0} src={best24004_5_1} delay={3000} />
                                        <Image width={0} src={best24004_5_2} delay={3000} />
                                    </Image.PreviewGroup>}
                                >
                                    <Meta
                                        title="Bardage & toiture"
                                        description="Finitions extérieures d'une Tiny House."
                                    />
                                </Card>
                            </div>
                        </div>
                    }
                    {
                        <div className="container-fluid" key={1}>
                            <div className="contentWrapper">
                                                                <Card
                                    className="project-card"
                                    cover=
                                    {<Image.PreviewGroup>
                                        <Image src={best24004_cover} />
                                    </Image.PreviewGroup>}
                                >
                                    <Meta
                                        title="Meubles de bureau"
                                        description="Plaqués chêne, assemblages invisibles."
                                    />
                                </Card>
                                <Card
                                    className="project-card"
                                    cover=
                                    {<Image.PreviewGroup>
                                        <Image src={best24002_cover} />
                                        <Image width={0} src={best24002_1} delay={4000} />
                                        <Image width={0} src={best24002_2} delay={4000} />
                                        <Image width={0} src={best24002_3} delay={4000} />
                                    </Image.PreviewGroup>}
                                >
                                    <Meta
                                        title="Cabanon de rangement"
                                        description="Matériaux sains, locaux et écologiques."
                                    />
                                </Card>
                                <Card
                                    className="project-card"
                                    cover=
                                    {<Image.PreviewGroup>
                                        <Image src={best24001_cover} />
                                    </Image.PreviewGroup>}
                                >
                                    <Meta
                                        title="Table extérieure"
                                        description="Bois massif, traité, pieds démontables."
                                    />
                                </Card>
                            </div>
                        </div>
                    }
                    {
                        <div className="container-fluid" key={2}>
                            <div className="contentWrapper">
                                <Card
                                    className="project-card"
                                    cover=
                                    {<Image.PreviewGroup>
                                        <Image src={best24000_cover} />
                                        <Image width={0} src={best24000_1} delay={5000} />
                                    </Image.PreviewGroup>}
                                >
                                    <Meta
                                        title="Toilette sèche"
                                        description="Solide, compacte et esthétique."
                                    />
                                </Card>
                            </div>
                        </div>
                    }
                    </Carousel>
                </div>
            </div>
        </div>
    );
}

export default AppProjects;