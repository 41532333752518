import React from "react";

import AppHero from "../components/home/hero";
import AppProjects from "../components/home/projects";
import AppContact from "../components/home/contact";

function AppHome() {
    return (
        <div className="main">
            <AppHero/>
            <AppProjects/>
            <AppContact/>
        </div>
    );
}

export default AppHome;